import angular from 'angular';

/**
 * @private
 */
class Controller {
  /**
   * @param {string} loginUrl The login URL.
   * @param {string} logoutUrl The logout URL.
   * @ngInject
   */
  constructor(loginUrl, logoutUrl) {
    // Binding properties

    /**
     * The login url
     * @type {string}
     * @export
     */
    this.loginUrl = loginUrl;

    /**
     * The logout url
     * @type {string}
     * @export
     */
    this.logoutUrl = logoutUrl;
  }

  /**
   * Login url initialization
   * @export
   */
  doLogin() {
    const returnUrl = encodeURIComponent(document.location.href);
    document.location = `${this.loginUrl}?came_from=${returnUrl}`;
  }

  /**
   * Logout url initialization
   * @export
   */
  doLogout() {
    const returnUrl = encodeURIComponent(document.location.href);
    document.location = `${this.logoutUrl}?came_from=${returnUrl}`;
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflAuthentication', []);

gmfModule.component('epflAuthentication', {
  bindings: {
    username: '<',
  },
  controller: Controller,
  template: () => require('./component.html'),
});

export default gmfModule;
