/**
 * @module epfl_authgeoportail.filters.NumberCoordinates
 */

import ngeoMiscFilters from 'ngeo/misc/filters.js';

/**
 * Round a number to a given number of decimals
 * @param {number} value The value.
 * @param {number} decimals The decimal number.
 * @returns {number} The rounded number.
 * @private
 */
function roundDecimal_(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

/**
 * @return {ngeox.numberCoordinates} A function to format numbers into coordinates string.
 * @constructor
 */
const epflNumberCoordinates = function () {
  /**
   * @param {ol.Coordinate} coordinates Array of two numbers.
   * @param {(number|string)=} opt_fractionDigits Optional number of digit.
   *     Default to 1.
   * @param {string=} opt_template Optional template. Default to '{x} {y}'.
   *     Where "{x}" will be replaced by the easting coordinate and "{y}" by the
   *     northing one. Note: Use a html entity to use the semicolon symbol
   *     into a template.
   * @return {string} Number formatted coordinates.
   */
  const filterFn = function (coordinates, opt_fractionDigits, opt_template) {
    const template = opt_template ? opt_template : '{x} {y}';
    const decimals = opt_fractionDigits === 0 ? 0 : opt_fractionDigits || 1;
    const x = roundDecimal_(coordinates[0], decimals);
    const y = roundDecimal_(coordinates[1], decimals);
    return template.replace('{x}', x.toString()).replace('{y}', y.toString());
  };
  return filterFn;
};

ngeoMiscFilters.filter('ngeoNumberCoordinates', epflNumberCoordinates); // overrides ngeoNumberCoordinates

export default ngeoMiscFilters;
