import angular from 'angular';
import gmfThemeThemes from 'gmf/theme/Themes.js';
import ngeoMapLayerHelper from 'ngeo/map/LayerHelper.js';
import ngeoPrintService from 'ngeo/print/Service.js';
import olMap from 'ol/Map.js';
import olView from 'ol/View.js';
import olFeature from 'ol/Feature.js';
import olGeomMultiPolygon from 'ol/geom/MultiPolygon.js';
import olSourceVector from 'ol/source/Vector.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleFill from 'ol/style/Fill.js';
import olLayerVector from 'ol/layer/Vector.js';

/**
 * @private
 */
class Controller {
  /**
   *
   * @param {angular.IHttpService} $http Angular $http service.
   * @param {angular.IScope} $scope Angular scope.
   * @param {angular.ITimeoutService} $timeout Angular timeout service.
   * @param {angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
   * @param {import("ngeo/print/Service.js").CreatePrint} ngeoCreatePrint The ngeo Create Print function.
   * @param {import("ngeo/map/LayerHelper.js").LayerHelper} ngeoLayerHelper Ngeo layer helper service.
   * @param {import("gmf/theme/Themes.js").ThemesService} gmfThemes Themes service.
   * @param {string} gmfPrintUrl A MapFishPrint url.
   * @param {string} facultyUrl Faculty url.
   * @ngInject
   */
  constructor(
    $http,
    $scope,
    $timeout,
    gettextCatalog,
    ngeoCreatePrint,
    ngeoLayerHelper,
    gmfThemes,
    gmfPrintUrl,
    facultyUrl
  ) {
    // Binding properties

    /**
     * @type {angular.IHttpService}
     * @private
     */
    this.$http_ = $http;

    /**
     * @type {angular.IScope}
     * @private
     */
    this.$scope_ = $scope;

    /**
     * @type {angular.ITimeoutService}
     * @private
     */
    this.$timeout_ = $timeout;

    /**
     * @type {Array<epflx.FacultyDatum>}
     * @export
     */
    this.facultyData = [];

    /**
     * @type {epflx.FacultyDatum}
     * @export
     */
    this.selectedFacultyDatum;

    /**
     * @type {number}
     */
    this.printDpi = 254;

    /**
     * @type {boolean}
     * @export
     */
    this.active = false;

    /**
     * @type {string}
     * @private
     */
    this.facultyUrl_ = facultyUrl;

    /**
     * @type {angular.gettext.gettextCatalog}
     * @private
     */
    this.gettextCatalog_ = gettextCatalog;

    /**
     * @type {import("ngeo/print/Service.js").CreatePrint}
     * @private
     */
    this.ngeoPrint_ = ngeoCreatePrint(gmfPrintUrl);

    /**
     * @type {import("ngeo/map/LayerHelper.js").LayerHelper}
     * @private
     */
    this.ngeoLayerHelper_ = ngeoLayerHelper;

    /**
     * @type {import("gmf/theme/Themes.js").ThemesService}
     * @private
     */
    this.gmfThemes_ = gmfThemes;

    /**
     * @type {ol.layer.Base}
     * @private
     */
    this.bgLayers_ = null;

    /**
     * @type {boolean}
     * @export
     */
    this.isPrinting = false;
  }

  $onInit() {
    const unWatch = this.$scope_.$watch(
      () => this.active,
      () => {
        if (this.active) {
          unWatch();

          // Get the faculties
          this.$http_.get(this.facultyUrl_).then((result) => {
            const faculties = result.data['features'];
            this.selectedFacultyDatum = faculties[0];
            this.facultyData = faculties;
          });

          // Add the background layer
          this.gmfThemes_.getBgLayers().then((layers) => {
            layers.forEach((layer) => {
              if (layer.get('label') === 'grp_backgrounds') {
                this.bgLayers_ = layer;
              }
            });
          });
        }
      }
    );
  }

  /**
   * Set the selected faculty
   * @param {epflx.FacultyDatum} faculty A faculty object.
   * @export
   */
  setFaculty(faculty) {
    this.selectedFacultyDatum = faculty;
  }

  /**
   * Get the page rotation
   * @returns {number} The rotation.
   * @private
   */
  getRotation_() {
    switch (this.selectedFacultyDatum.properties.orientation) {
      case 'EST':
        return 270;
      case 'SUD':
        return 180;
      case 'OUEST':
        return 90;
      default:
        return 0;
    }
  }

  /**
   * Print a faculty report
   */
  print() {
    // If the print is already running, just stop
    if (this.isPrinting) {
      return;
    }

    const rotation = this.getRotation_();
    const datasource = [];
    const format = 'pdf';
    const layout = '1 A3 landscape faculties';
    const lang = this.gettextCatalog_.currentLanguage;
    this.isPrinting = true;

    const floor = this.selectedFacultyDatum.properties.etage;
    const label = this.selectedFacultyDatum.properties.label;

    const customAttributes = {
      datasource: datasource,
      //lang: lang,
      //rotation: rotation,
      batiment: label,
      floor: floor,
    };

    // Create the map
    const map = new olMap({
      view: new olView({
        projection: 'EPSG:2056',
      }),
    });

    // Hack the center and resolution to not throw errors with the print spec generation
    map.getView().setResolution(1);
    map.getView().setCenter([260000, 120000]);

    map.addLayer(this.bgLayers_);

    // Add arrow symbol
    const arrowFeature = new olFeature({
      geometry: new olGeomMultiPolygon(this.selectedFacultyDatum.geometry.coordinates),
    });
    const source = new olSourceVector({
      features: [arrowFeature],
    });
    const style = new olStyleStyle({
      stroke: new olStyleStroke({
        // Stroke is badly rendered
        width: 0,
      }),
      fill: new olStyleFill({
        color: [255, 0, 0],
      }),
    });
    const visible = true;
    const arrowLayer = new olLayerVector({
      source,
      style,
      visible,
    });
    map.addLayer(arrowLayer);

    // Create the spec
    const spec = this.ngeoPrint_.createSpec(
      map,
      null,
      rotation,
      this.printDpi,
      layout,
      format,
      customAttributes
    );

    spec.attributes.map.layers.forEach((layer) => {
      //Set the floor
      if (layer['dimensionParams'] && layer['dimensionParams']['floor']) {
        layer['dimensionParams']['floor'] = floor;
      }
      if (layer['customParams']) {
        if (layer['customParams']['floor']) {
          layer['customParams']['floor'] = floor;
        }
        if (layer['customParams']['lang']) {
          layer['customParams']['lang'] = lang;
        }
      }

      // Hack to call the faculty-specific background layer
      // https://jira.camptocamp.com/browse/GSEPF-89
      if (layer.layers) {
        const idx = layer.layers.findIndex((value) => value === 'locaux_labels');
        layer.layers[idx] = 'locaux_labels_faculty';
      }
    });

    // Set the print bbox, remove center attribute
    spec.attributes.map['bbox'] = this.selectedFacultyDatum.properties.bbox
      .split(',')
      .map((value) => parseFloat(value));
    delete spec.attributes.map['center'];

    // BBOX[1]: min_x, BBOX[2]: min_y, BBOX[2]: max_x, BBOX[3]: max_y
    const xLength = spec.attributes.map['bbox'][2] - spec.attributes.map['bbox'][0];
    const yLength = spec.attributes.map['bbox'][3] - spec.attributes.map['bbox'][1];

    // If the ratio is smaller than 1, this is a bbox to rotate
    // We transform the bbox from vertical to horizontal rectangle orientation
    if (xLength / yLength < 1) {
      const center = [
        spec.attributes.map['bbox'][2] - xLength / 2,
        spec.attributes.map['bbox'][3] - yLength / 2,
      ];
      const centerX = center[0];
      const centerY = center[1];
      const halfX = xLength / 2;
      const halfY = yLength / 2;
      const minX = centerX - halfY;
      const minY = centerY - halfX;
      const maxX = centerX + halfY;
      const maxY = centerY + halfX;
      spec.attributes.map['bbox'] = [minX, minY, maxX, maxY];
    }

    // Create the report
    this.ngeoPrint_.createReport(spec).then((result) => {
      this.getStatus_(result.data.ref, result);
    });
  }

  /**
   * @param {string} ref Ref.
   * @param {Object} resp Response.
   * @private
   */
  getStatus_(ref, resp) {
    const mfResp = /** @type {MapFishPrintStatusResponse} */ (resp.data);
    const done = mfResp.done;
    let mfRef = ref;

    if (resp.status === 200 && done) {
      // The report is ready. Open it by changing the window location.
      window.location.href = this.ngeoPrint_.getReportUrl(mfRef);
      this.isPrinting = false;
      mfRef = '';
    } else {
      // The report is not ready yet. Check again in 1s.
      this.$timeout_(
        () => {
          this.ngeoPrint_.getStatus(mfRef).then((resp) => {
            this.getStatus_(mfRef, resp);
          });
        },
        1000,
        false
      );
    }
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflPrintFaculty', [
  gmfThemeThemes.name,
  ngeoMapLayerHelper.name,
  ngeoPrintService.name,
]);

gmfModule.component('epflPrintFaculty', {
  bindings: {
    active: '=epflFacultyActive',
  },
  controller: Controller,
  template: () => require('./printfaculty.html'),
});

export default gmfModule;
