import angular from 'angular';

class Controller {
  /**
   * @param {angular.IHttpService} $http Angular Http service.
   * @param {angular.ITimeoutService} $timeout Angular timeout service.
   * @param {angular.gettext.gettextCatalog} gettextCatalog Gettext catalog.
   * @param {string} routingUrl The URL to the routing service.
   * @param {import("gmf/theme/Themes.js").ThemesService} gmfThemes The gmf themes service.
   * @param {import("ngeo/map/LayerHelper.js").LayerHelper} ngeoLayerHelper Ngeo Layer Helper.
   * @ngInject
   */
  constructor($http, $timeout, gettextCatalog, routingUrl, gmfThemes, ngeoLayerHelper) {
    /**
     * @type {angular.IHttpService}
     * @private
     */
    this.$http_ = $http;

    /**
     * @type {angular.ITimeoutService}
     * @private
     */
    this.$timeout_ = $timeout;

    /**
     * @type {angular.gettext.gettextCatalog}
     * @private
     */
    this.gettextCatalog = gettextCatalog;

    /**
     * @type {string}
     * @private
     */
    this.routingUrl_ = routingUrl;

    /**
     * @type {!ol.Map}
     */
    this.map;

    /**
     * @type {boolean}
     * @export
     */
    this.publishState = false;

    /**
     * @type {boolean}
     * @export
     */
    this.validateState = false;

    /**
     * @type {string}
     * @export
     */
    this.message;

    /**
     * @type {!ol.Layer}
     * @export
     */
    this.validationLayer;

    /**
     * @type {import("gmf/theme/Themes.js").ThemesService}
     * @private
     */
    this.gmfThemes_ = gmfThemes;

    /**
     * @type {import("ngeo/map/LayerHelper.js").LayerHelper}
     * @private
     */
    this.ngeoLayerHelper_ = ngeoLayerHelper;

    /**
     * @type {string}
     * @private
     */
    this.validationLayerName_ = 'routingvalidation';

    /**
     * @type {boolean}
     * @export
     */
    this.ongoing = false;
  }

  isOngoing() {
    return this.ongoing;
  }

  publish() {
    this.resetState();
    this.message = this.gettextCatalog.getString('Routing data is getting validated, please wait.');
    this.publishState = true;
    this.ongoing = true;

    const token = `publish_${Date.now()}`;
    const params = {'params': {'action': 'publish', 'token': token}};
    this.$http_
      .get(this.routingUrl_, params)
      .catch((error) => {
        //this.message = this.gettextCatalog.getString('An error occured when trying to publish the routing data.');
      })
      .then((response) => {
        this.getStatus_(token);
      });
  }

  validate() {
    this.resetState();
    this.message = this.gettextCatalog.getString('Routing errors is getting calculated, please wait.');
    this.validateState = true;
    this.ongoing = true;

    const token = `validation_${Date.now()}`;
    const params = {'params': {'action': 'validate', 'token': token}};
    this.$http_
      .get(this.routingUrl_, params)
      .catch((error) => {
        //this.message = this.gettextCatalog.getString('An error occured when trying to validate the routing data.');
      })
      .then((response) => {
        this.getStatus_(token);
      });
  }

  /**
   * @param {Object} token .
   */
  getStatus_(token) {
    const params = {'params': {'action': 'status', 'token': token}};
    this.$http_
      .get(this.routingUrl_, params)
      .then((resp) => {
        this.handleStatusSuccess(resp, token);
      })
      .catch((error) => {
        this.handleError(token);
      });
  }

  handleStatusSuccess(resp, token) {
    const response = resp.data;
    if (response.state !== 'ongoing') {
      if (response.process.includes('validation')) {
        this.message = this.gettextCatalog.getString('Routing errors has been calulated.');
        this.message += `<br>Nombre d'erreurs identifiées: ${response.nb_errors}`;

        // refresh OLlayer containing the itineraries errors
        const group = this.ngeoLayerHelper_.getGroupFromMap(this.map, 'data');
        const layers = this.ngeoLayerHelper_.getFlatLayers(group);
        const OLlayer = this.ngeoLayerHelper_.getLayerByName('itineraire_group_pending', layers);
        if (OLlayer) {
          this.ngeoLayerHelper_.refreshWMSLayer(OLlayer);
        }
      } else {
        // if it's not validation, it's publish
        this.message = this.gettextCatalog.getString('Routing data has been validated.');
        this.message += `<br>${this.gettextCatalog.getString('Validated: Vertices:')} ${
          response.nb_vertices
        }, ${this.gettextCatalog.getString('Edges:')} ${response.nb_edges}`;
      }
      this.ongoing = false;
    } else {
      // The process is not done yet. Check again in 5s.
      this.statusTimeoutPromise_ = this.$timeout_(
        () => {
          this.getStatus_(token);
        },
        5000,
        false
      );
    }
  }

  resetState() {
    this.publishState = false;
    this.validateState = false;
  }

  /**
   * @param {Object} token .
   */
  handleError(token) {
    if (token.includes('validation')) {
      // if it's validation
      this.message = this.gettextCatalog.getString(
        'An error occured when trying to calculate the routing errors.'
      );
    } else {
      // if it's publish
      this.message = this.gettextCatalog.getString(
        'An error occured when trying to validate the routing data.'
      );
    }
    this.ongoing = false;
  }
}

/**
 * @hidden
 */
const gmfModule = angular.module('epflRoutingTools', []);

gmfModule.component('epflRoutingTools', {
  bindings: {
    'map': '<',
  },
  controller: Controller,
  template: () => require('./routingtools.html'),
});

export default gmfModule;
